<template>
    <div class="row no-gutters align-items-top" :class="task.modified && 'modified-row'">
        <div class="col-1 checked-col">
            <b-checkbox :disabled="disabled" v-model="task.checked" />
        </div>
        <div class="col-2 operation-name-col" @click="onOperationNameClick">
            <b class="operation-name">{{ task.operation.name }}</b>
        </div>
        <div class="col-3 nomenclature-name-col" @click="onNomenclatureNameClick">
            <span class="nomenclature-name">{{ task.nomenclature.name }}</span>
        </div>
        <div class="col-2 from-previous-shift-col">
            <b-badge v-if="task.fromPreviousShift">С прошлой смены</b-badge>
        </div>
        <div class="col-2">
            <b-row no-gutters class="produced-count-row">
                <template v-if="task.checked">
                    <div class="col text-right">
                        <b-form-input
                            size="sm"
                            class="produced-count"
                            :value="task.producedCount"
                            :state="producedCountState"
                            @input="onProducedCountInput"
                            @change="onProducedCountChange"
                            :disabled="disabled"
                            :formatter="producedCountFormatter"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="col produced-count-col text-right">
                        <b>{{ task.producedCount }}</b>
                    </div>
                </template>
                <div class="col-1 sol-col text-center">&sol;</div>
                <div class="col required-count-col">
                    <span>{{ task.requiredCount }}</span>
                    <span>{{ task.nomenclature.measure_unit.name }}</span>
                </div>
            </b-row>
        </div>
        <div class="col-2 assume-finished-col">
            <b-checkbox
                v-if="showAssumeFinished"
                :checked="task.assumeFinished"
                :disabled="disabled"
                @change="onAssumeFinishedChange"
            >
                <span class="assume-finished">Считать завершенным</span>
            </b-checkbox>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Task",
        props: {
            task: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            producedCountState() {
                if (this.task.modified) {
                    return (
                        this.task.producedCount >= this.task.blockedProducedCount
                    );
                }
                return null;
            },
            showAssumeFinished() {
                return (
                    this.task.checked &&
                    this.task.producedCount > 0 &&
                    this.task.producedCount < this.task.requiredCount
                );
            }
        },
        methods: {
            onOperationNameClick() {
                if (this.disabled) {
                    return;
                }
                this.task.checked = !this.task.checked;
                this.$emit("update:task", this.task);
            },
            onNomenclatureNameClick() {
                if (this.disabled) {
                    return;
                }
                this.task.checked = !this.task.checked;
                this.$emit("update:task", this.task);
            },
            producedCountFormatter(value) {
                return value
                    .toString()
                    .replace(",", ".")
                    .replace(/[^\d.]/g, "");
            },
            onProducedCountInput(value) {
                this.task.producedCount = Number.parseFloat(value);
                this.task.modified = this.isModified();
            },
            onProducedCountChange(value) {
                this.task.producedCount = Number.parseFloat(value);
                if (Number.isNaN(this.task.producedCount)) {
                    this.task.producedCount = this.task.loadedProducedCount;
                }
                if (this.task.producedCount < this.task.blockedProducedCount) {
                    this.task.producedCount = this.task.blockedProducedCount;
                }
                this.task.modified = this.isModified();
                this.$emit("update:task", this.task);
            },
            onAssumeFinishedChange(checked) {
                this.task.assumeFinished = checked;
                this.task.modified = this.isModified();
                this.$emit("update:task", this.task);
            },
            fillProducedCount() {
                if (this.task.checked) {
                    this.task.producedCount = this.task.requiredCount;
                    this.task.modified = this.isModified();
                    this.$emit("update:task", this.task);
                }
            },
            isModified() {
                return (
                    this.task.assumeFinished !== this.task.loadedAssumeFinished ||
                    this.task.producedCount !== this.task.loadedProducedCount
                );
            }
        }
    };
</script>

<style scoped>
    .row {
        min-height: 28px;
        padding-top: 5px;
        padding-bottom: 4px;
    }
    .modified-row {
        background-color: #ddfbe6;
    }
    .checked-col {
        max-width: 30px;
        padding-top: 3px;
        padding-left: 6px;
    }
    .from-previous-shift-col {
        max-width: 115px;
        padding-top: 2px;
        padding-left: 5px;
    }
    .operation-name-col {
        padding-top: 3px;
    }
    .operation-name {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .operation-name:hover {
        cursor: pointer;
        overflow: visible;
        white-space: normal;
    }
    .nomenclature-name-col {
        padding-top: 3px;
    }
    .nomenclature-name {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .nomenclature-name:hover {
        cursor: pointer;
        overflow: visible;
        white-space: normal;
    }
    .produced-count-row {
        padding: 0 !important;
    }
    .produced-count {
        width: 95%;
        height: 27px;
        text-align: right;
        display: inline-block;
    }
    .produced-count-col {
        padding-top: 3px;
    }
    .sol-col {
        padding-top: 2px;
    }
    .required-count-col {
        padding-top: 3px;
    }
    .assume-finished-col {
        padding-top: 3px;
    }
    .assume-finished {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
</style>
