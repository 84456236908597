<template>
    <div class="container-fluid mt-1 mb-1">
        <div class="row mt-1 mb-1">
            <div class="col" role="tab">
                <span class="name" v-b-toggle="name + '-transfer-group-accardion'">
                    <span class="when-opened">&plus;</span>
                    <span class="when-closed">&minus;</span>
                    <span>{{ title }}</span>
                    <b>({{ transfers.length }})</b>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col transfer-group-panel-col">
                <b-collapse
                    :id="name + '-transfer-group-accardion'"
                    accordion="transfer-group-accardion"
                    v-model="expanded"
                    role="tabpanel"
                >
                    <transfer
                        ref="transfers"
                        v-for="transfer in transfers"
                        :key="transfer.id"
                        :transfer="transfer"
                        :disabled="disabled"
                        v-on:update:transfer="onTransferUpdate"
                    />
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    import Transfer from "./Transfer"

    export default {
        name: "TransferGroup",
        props: {
            title: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            transfers: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                expanded: false
            }
        },
        components: {
            Transfer
        },
        methods: {
            fillMaterialTransfers() {
                if (this.expanded) {
                    this.$refs.transfers.forEach($transfer => $transfer.setChecked())
                }
            },
            onTransferUpdate(transfer) {
                this.$emit("update:transfer", transfer)
            }
        }
    }
</script>

<style scoped>
    .container-fluid {
        padding: 0 10px !important;
    }
    .name {
        cursor: pointer;
        font-size: 1rem;
        user-select: none;
    }
    .transfer-group-panel-col {
        padding-left: 7px;
        padding-right: 7px;
    }
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>
