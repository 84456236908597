<template>
    <div class="container-fluid">
        <div v-if="alert" class="row mb-3">
            <div class="col text-center">
                <div :class="'alert alert-' + alert.type">
                    <span v-if="alert.message" class="pl-1 pr-1">{{ alert.message }}</span>
                    <b v-if="alert.progress" class="pl-1 pr-1">({{ alert.progress }})</b>
                    <i v-if="alert.loading" class="fas fa-spin fa-spinner pl-1 pr-1"></i>
                </div>
            </div>
        </div>
        <div v-else class="container-fluid mb-2">
            <div class="row mb-5">
                <div class="col">
                    <b-button
                        block
                        @click="fillMaterialTransfers"
                        :disabled="disabled"
                        variant="warning"
                        size="md"
                    >Заполнить</b-button>
                </div>
                <div class="col">
                    <b-button
                        block
                        @click="saveMaterialTransfers"
                        :disabled="disabled"
                        variant="info"
                        size="md"
                    >Сохранить</b-button>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <h5 class="mb-0">Передачи на текущую смену</h5>
                </div>
            </div>
            <transfer-group
                title="Неподтвержденные"
                name="current-shift-not-received-transfers"
                ref="currentShiftNotReceivedTransfersGroup"
                :transfers="currentShiftNotReceivedTransfers"
                :disabled="disabled"
                v-on:update:transfer="onTransferUpdate"
            />
            <transfer-group
                title="Подтвержденные"
                name="current-shift-received-transfers"
                ref="currentShiftReceivedTransfersGroup"
                :transfers="currentShiftReceivedTransfers"
                :disabled="disabled"
                v-on:update:transfer="onTransferUpdate"
            />
            <div class="row">
                <div class="col text-center">
                    <h5 class="mb-0">Передачи на следующую смену</h5>
                </div>
            </div>
            <transfer-group
                title="Неподтвержденные"
                name="next-shift-not-received-transfers"
                ref="nextShiftNotReceivedTransfersGroup"
                :transfers="nextShiftNotReceivedTransfers"
                :disabled="disabled"
                v-on:update:transfer="onTransferUpdate"
            />
            <transfer-group
                title="Подтвержденные"
                name="next-shift-received-transfers"
                ref="nextShiftReceivedTransfersGroup"
                :transfers="nextShiftReceivedTransfers"
                :disabled="disabled"
                v-on:update:transfer="onTransferUpdate"
            />
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    import { MATERIAL_TRANSFER_ACT_ENDPOINT } from "../../../../utils/endpoints"
    import TransferGroup from "./TransferGroup"

    const WITH = [
        "storageFrom",
        "storageTo",
        "items.nomenclatureLot.nomenclature.measureUnit"
    ]

    export default {
        name: "Transfers",
        props: {
            act: {
                type: Object,
                required: true
            },
            actualShift: {
                type: Object,
                required: true
            },
            storage: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
            TransferGroup
        },
        data() {
            return {
                alert: null,
                loading: false,
                message: null,
                progress: null,
                transfers: []
            }
        },
        computed: {
            currentShiftReceivedTransfers() {
                return this.transfers.filter(t => t.actual_shift_id === t.target_actual_shift_id && t.received)
            },
            currentShiftNotReceivedTransfers() {
                return this.transfers.filter(t => t.actual_shift_id === t.target_actual_shift_id && !t.received)
            },
            nextShiftReceivedTransfers() {
                return this.transfers.filter(t => t.actual_shift_id !== t.target_actual_shift_id && t.received)
            },
            nextShiftNotReceivedTransfers() {
                return this.transfers.filter(t => t.actual_shift_id !== t.target_actual_shift_id && !t.received)
            }
        },
        methods: {
            loadMaterialTransfers() {
                this.alert = {
                    type: "info",
                    loading: true,
                    message: "Получаем список непринятых передач"
                }

                const filters = {
                    storage_to_ids: [this.storage.id],
                    actual_shift_id: this.actualShift.id,
                    canceled: 0
                }

                this.$http
                    .get(MATERIAL_TRANSFER_ACT_ENDPOINT, {
                        params: {
                            filters,
                            with: WITH,
                            limit: 10000,
                            without_loading: true
                        }
                    })
                    .then(response => response.data.data)
                    .then(data => {
                        this.transfers = data.map(this.assignLocalValues)
                        if (this.transfers.length === 0) {
                            this.alert = {
                                type: "success",
                                message: "Нет непринятых передач"
                            }
                        } else {
                            this.alert = null
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.alert = {
                            type: "danger",
                            message: "Не удалось получить список непринятых передач"
                        }
                    })
            },
            assignLocalValues(transfer) {
                transfer.checked = transfer.received
                transfer.modified = false
                return transfer
            },
            fillMaterialTransfers() {
                this.$refs.currentShiftNotReceivedTransfersGroup.fillMaterialTransfers()
                this.$refs.currentShiftReceivedTransfersGroup.fillMaterialTransfers()
                this.$refs.nextShiftNotReceivedTransfersGroup.fillMaterialTransfers()
                this.$refs.nextShiftReceivedTransfersGroup.fillMaterialTransfers()
            },
            saveMaterialTransfers() {
                const transfers = this.transfers.filter(transfer => transfer.modified)
                const promises = transfers.map(this.saveMaterialTransferPromise)

                let current = 0
                const total = promises.length

                this.alert = {
                    type: "info",
                    loading: true,
                    message: "Сохраняем список подтвержденных передач",
                    progress: current + "/" + total
                }

                for (const promise of promises) {
                    promise.then(transfer => {
                        this.alert.progress = ++current + "/" + total
                        const index = transfers.findIndex(t => t.key === transfer.key)
                        transfers[index] = transfer
                    })
                }

                Promise.all(promises)
                    .then(() => {
                        this.alert = null
                    })
                    .catch(error => {
                        console.log(error)
                        this.alert = {
                            type: "danger",
                            message: "Не удалось сохранить список подтвержденных передач"
                        }
                    })
                    .finally(() => {
                        this.loadMaterialTransfers()
                    })
            },
            saveMaterialTransferPromise({ id, checked }) {
                return this.$http
                    .put(
                        `${MATERIAL_TRANSFER_ACT_ENDPOINT}/${id}/receive`,
                        {
                            received: checked,
                            with: WITH
                        },
                        {
                            params: {
                                without_loading: true
                            }
                        }
                    )
                    .then(response => {
                        return response.data
                    })
            },
            onTransferUpdate(transfer) {
                const index = this.transfers.findIndex(t => t.id === transfer.id)
                this.transfers[index] = transfer
            }
        },
        mounted() {
            this.loadMaterialTransfers()
        }
    }
</script>

<style scoped>
    .container-fluid {
        overflow-x: visible;
        padding: 0 !important;
    }
    .alert {
        margin-bottom: 0 !important;
    }
</style>
