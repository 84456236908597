<template>
    <div class="container-fluid">
        <div v-if="alert" class="row mb-3">
            <div class="col text-center">
                <div :class="'alert alert-' + alert.type">
                    <span v-if="alert.message" class="pl-1 pr-1">{{ alert.message }}</span>
                    <b v-if="alert.progress" class="pl-1 pr-1">({{ alert.progress }})</b>
                    <i v-if="alert.loading" class="fas fa-spin fa-spinner pl-1 pr-1"></i>
                </div>
            </div>
        </div>
        <div v-else class="container-fluid mb-2">
            <div v-if="showDetailedStats" class="row justify-content-md-center mb-5">
                <div class="col col-lg-4 col-md-12">
                    <div class="row">
                        <div class="col col-9">
                            <h1 class="text-left">
                                {{ totalProducedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;") }} /
                                {{ totalRequiredCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;") }} шт
                            </h1>
                        </div>
                        <div class="col">
                            <h1 class="text-right" :style="{'color': totalProducedPercent < 100 ? 'red' : 'green'}">
                                {{ totalProducedPercent }}%
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b-progress height="20px" :max="totalRequiredCount" class="mb-3">
                                <b-progress-bar :value="producedFromPrevShift" :label="formatNumber(producedFromPrevShift) + ' шт'" v-if="producedFromPrevShift > 0" v-b-tooltip.hover title="Сделано из предыдущей смены" variant="warning" :striped="true" />
                                <b-progress-bar :value="producedFromCurrShift" :label="formatNumber(producedFromCurrShift) + ' шт'" v-if="producedFromCurrShift > 0" v-b-tooltip.hover title="Сделано из текущей смены" variant="success" :striped="true" />
                                <b-progress-bar :value="producedFromNextShift" :label="formatNumber(producedFromNextShift) + ' шт'" v-if="producedFromNextShift > 0" v-b-tooltip.hover title="Сделано из следующей смены" variant="info" :striped="true" />
                            </b-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col">
                    <b-button :disabled="disabled" block @click="fillProducedCount" variant="warning" size="md">Заполнить</b-button>
                </div>
                <div class="col">
                    <b-button :disabled="disabled" block @click="saveProductionTasks" variant="info" size="md">Сохранить</b-button>
                </div>
            </div>
            <task-group
                title="В работе"
                ref="startedTaskGroup"
                :status="STATUS_STARTED"
                :tasks="startedTasks"
                :disabled="disabled"
                v-on:update:task="onTaskUpdate"
            />
            <task-group
                title="Не начатые"
                ref="pendingTaskGroup"
                :status="STATUS_PENDING"
                :tasks="pendingTasks"
                :disabled="disabled"
                v-on:update:task="onTaskUpdate"
            />
            <task-group
                title="Завершенные"
                ref="finishedTaskGroup"
                :status="STATUS_FINISHED"
                :tasks="finishedTasks"
                :disabled="disabled"
                v-on:update:task="onTaskUpdate"
            />
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {
        PRODUCTION_TASKS_ENDPOINT,
        PRODUCTION_ACTS_ENDPOINT
    } from "../../../../utils/endpoints";
    import BarcodeRepository from "../../../../utils/BarcodeRepository";
    import TaskGroup from "./TaskGroup";

    const STATUS_HIDDEN = "hidden";
    const STATUS_STARTED = "started";
    const STATUS_PENDING = "pending";
    const STATUS_FINISHED = "finished";

    const PACKING_STORAGE_ID = 1;
    const BOTTLING_STORAGE_ID = 28;

    export default {
        name: "Tasks",
        props: {
            act: {
                type: Object,
                required: true
            },
            actualShift: {
                type: Object,
                required: true
            },
            storage: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
            TaskGroup
        },
        data() {
            return {
                STATUS_STARTED,
                STATUS_PENDING,
                STATUS_FINISHED,
                alert: null,
                loading: false,
                message: null,
                progress: null,
                tasks: []
            };
        },
        computed: {
            startedTasks() {
                return this.tasks.filter(task => task.status === STATUS_STARTED);
            },
            pendingTasks() {
                return this.tasks.filter(task => task.status === STATUS_PENDING);
            },
            finishedTasks() {
                return this.tasks.filter(task => task.status === STATUS_FINISHED);
            },
            showDetailedStats() {
                return this.storage.id === PACKING_STORAGE_ID || this.storage.id === BOTTLING_STORAGE_ID;
            },
            fromNextShiftTasks() {
                return this.tasks.filter(task => task.status === STATUS_FROM_NEXT_SHIFT);
            },
            producedFromPrevShift() {
                return this.tasks
                    .filter(task => task.fromPreviousShift)
                    .reduce((total, task) => total + (task.producedCount || 0) - task.blockedProducedCount, 0);
            },
            producedFromCurrShift() {
                return this.tasks
                    .filter(task => !task.fromPreviousShift && !task.fromNextShift)
                    .reduce((total, task) => total + (task.producedCount || 0) - task.blockedProducedCount, 0);
            },
            producedFromNextShift() {
                return this.tasks
                    .filter(task => task.fromNextShift)
                    .reduce((total, task) => total + (task.producedCount || 0) - task.blockedProducedCount, 0);
            },
            totalProducedCount() {
                return this.producedFromPrevShift + this.producedFromCurrShift + this.producedFromNextShift;
            },
            totalRequiredCount() {
                return this.tasks
                    .filter(task => !task.fromPreviousShift && !task.fromNextShift)
                    .reduce((total, task) => total + task.requiredCount - task.blockedProducedCount, 0);
            },
            totalProducedPercent() {
                return Math.round(this.totalProducedCount / this.totalRequiredCount * 100);
            }
        },
        methods: {
            loadProductionTasks() {
                this.alert = {
                    type: "info",
                    loading: true,
                    message: "Получаем список заданий"
                };

                this.$http
                    .get(PRODUCTION_TASKS_ENDPOINT + "/dashboard", {
                        params: {
                            actual_shift_id: this.actualShift.id,
                            storage_id: this.storage.id,
                            with_next_shift_tasks: true,

                            without_loading: true
                        }
                    })
                    .then(response => {
                        this.tasks = response.data.tasks
                            .map(this.assignDefaultValues)
                            .map(this.assignAssumeFinished)
                            .map(this.assignProducedCount)
                            .map(this.assignStatus);
                        if (this.tasks.length === 0) {
                            this.alert = {
                                type: "success",
                                message: "Нет незавершенных заданий"
                            };
                        } else {
                            this.alert = null;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.alert = {
                            type: "danger",
                            message: "Не удалось получить список заданий"
                        };
                    });
            },
            fillProducedCount() {
                this.$refs.startedTaskGroup.fillProducedCount();
                this.$refs.pendingTaskGroup.fillProducedCount();
                this.$refs.finishedTaskGroup.fillProducedCount();
            },
            saveProductionTasks() {
                const tasks = this.tasks.filter(task => task.modified);
                const promises = tasks.map(this.saveProductionTaskPromise);

                let current = 0;
                const total = promises.length;

                this.alert = {
                    type: "info",
                    loading: true,
                    message: "Сохраняем список заданий",
                    progress: current + "/" + total
                };

                for (const promise of promises) {
                    promise.then(task => {
                        this.alert.progress = ++current + "/" + total;
                        const index = tasks.findIndex(t => t.key === task.key);
                        tasks[index] = task;
                    });
                }

                Promise.all(promises)
                    .then(() => {
                        this.alert = null;
                    })
                    .catch(error => {
                        console.log(error);
                        this.alert = {
                            type: "danger",
                            message: "Не удалось сохранить список заданий"
                        };
                    })
                    .finally(() => {
                        this.loadProductionTasks();
                    });
            },
            saveProductionTaskPromise(task) {
                return Promise.resolve(task)
                    .then(task => {
                        if (task.productionActs.length === 0) {
                            return this.createProductionActPromise(task).then(
                                productionAct => {
                                    task.productionActs.push(productionAct);
                                    return task;
                                }
                            );
                        }
                        return task;
                    })
                    .then(task => {
                        const productionAct = task.productionActs[0];
                        const count =
                            task.producedCount - task.blockedProducedCount;
                        const index = productionAct.items.findIndex(
                            item => !item.blocked
                        );
                        if (index !== -1) {
                            if (count > 0) {
                                productionAct.items[index].count = count;
                                if (!productionAct.items[index].plan_count) {
                                    productionAct.items[index].plan_count = count;
                                }
                            } else {
                                productionAct.items.splice(index, 1);
                            }
                        } else {
                            if (count > 0) {
                                productionAct.items.push({
                                    id: null,
                                    box: {
                                        name: this.generateUniqueBoxName(productionAct)
                                    },
                                    plan_count: count,
                                    count,
                                });
                            }
                        }
                        productionAct.assume_finished = task.assumeFinished;
                        return this.updateProductionActPromise(productionAct, task);
                    });
            },
            createProductionActPromise(task) {
                const rawMaterials = [];
                this.appendRawMaterial(task, rawMaterials);
                return this.$http
                    .post(
                        PRODUCTION_ACTS_ENDPOINT,
                        {
                            start_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                            shift: { id: this.act.productionShift.id },
                            nomenclature: { id: task.nomenclature.id },
                            storage: this.storage,
                            tasks: [task],
                            rawMaterials,
                            use_proportional_raw_materials: false
                        },
                        {
                            params: {
                                without_loading: true
                            }
                        }
                    )
                    .then(response => {
                        return response.data.data;
                    });
            },
            updateProductionActPromise(productionAct, task) {
                const rawMaterials = [];
                this.appendRawMaterial(task, rawMaterials);
                productionAct.shift = { id: this.act.productionShift.id };
                productionAct.nomenclature = { id: task.nomenclature.id };
                productionAct.rawMaterials = rawMaterials;
                productionAct.storage = this.storage;
                return this.$http
                    .put(
                        PRODUCTION_ACTS_ENDPOINT + "/" + productionAct.id,
                        productionAct,
                        {
                            params: {
                                without_loading: true
                            }
                        }
                    )
                    .then(response => {
                        return response.data.data;
                    });
            },
            generateUniqueBoxName(productionAct) {
                const name =
                    BarcodeRepository.boxPrefixes[1] +
                    Math.floor(
                        Math.random() * (99999 - 10000 + 1) + 10000
                    ).toString();
                const index = productionAct.items.findIndex(
                    item => item.box.name === name
                );
                if (index !== -1) {
                    return generateUniqueBoxName(productionAct);
                }
                return name;
            },
            assignDefaultValues(task) {
                task.id = task.ids[0];
                task.checked = false;
                task.modified = false;
                return task;
            },
            assignProducedCount(task) {
                task.producedCount = 0;
                task.blockedProducedCount = 0;
                task.productionActs.forEach(productionAct => {
                    productionAct.items.forEach(item => {
                        task.producedCount += item.count;
                        if (item.blocked) {
                            task.blockedProducedCount += item.count;
                        }
                    });
                });
                task.requiredCount = Number(task.count.toFixed(3));
                task.producedCount = Number(task.producedCount.toFixed(3));
                task.blockedProducedCount = Number(
                    task.blockedProducedCount.toFixed(3)
                );
                task.loadedProducedCount = task.producedCount;
                return task;
            },
            assignAssumeFinished(task) {
                if (task.productionActs.length > 0) {
                    task.assumeFinished = task.productionActs.every(
                        productionAct => productionAct.assume_finished
                    );
                } else {
                    task.assumeFinished = false;
                }
                task.loadedAssumeFinished = task.assumeFinished;
                return task;
            },
            assignStatus(task) {
                if (task.fromNextShift) {
                    task.status = STATUS_HIDDEN;
                    return task;
                }
                if (!task.productionActs.length) {
                    task.status = STATUS_PENDING;
                    return task;
                }
                if (task.producedCount >= task.requiredCount) {
                    task.status = STATUS_FINISHED;
                    return task;
                }
                if (task.assumeFinished) {
                    task.status = STATUS_FINISHED;
                    return task;
                }
                task.status = STATUS_STARTED;
                return task;
            },
            appendRawMaterial(task, result) {
                task.stuff.map(s => {
                    const index = result.findIndex(
                        m => m.nomenclature.id === s.nomenclature_id
                    );
                    if (index === -1) {
                        result.push({
                            nomenclature: { id: s.nomenclature_id },
                            count: parseFloat(s.count)
                        });
                    } else {
                        result[index].count += parseFloat(s.count);
                    }
                });
            },
            onTaskUpdate(task) {
                const index = this.tasks.findIndex(t => t.key === task.key);
                this.tasks[index] = task;
            },
            formatNumber(num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;");
            }
        },
        mounted() {
            this.loadProductionTasks();
        }
    };
</script>

<style scoped>
    .container-fluid {
        overflow-x: visible;
        padding: 0 !important;
    }
    .alert {
        margin-bottom: 0 !important;
    }
    .progress-bar:hover {
        cursor: help;
    }
</style>
