<template>
    <div class="card-body">
        <div>
            <div v-if="showSaveButton" class="card-body text-right" style="border-bottom:1px solid #c8ced3; position: sticky; top: 55px; background-color: white; z-index: 100">
                <div class="btn-group">
                    <button @click="$emit('save-rests')" :disabled="disabled || act.blocked || loading" class="btn btn-success">Сохранить</button>
                </div>
            </div>
            <table class="table table-sm table-bordered table-hover">
                <thead class="thead-dark">
                <tr>
                    <th colspan="4">Выпуск <i class="fas fa-question-circle" title="П/ф, покидающие цех"></i></th>
                </tr>
                <tr>
                    <th>Номенклатура</th>
                    <th class="text-right">План выпуска</th>
                    <th class="text-right">Факт выпуска</th>
                    <th class="text-right">На остатках</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="4">
                        <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                    </td>
                </tr>
                <tr v-else-if="productionItems.length === 0">
                    <td colspan="4">
                        <div class="alert alert-warning text-center">Нет данных</div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="item in productionItems">
                        <td :class="{'alert-danger': hasTrouble(item)}">{{item.nomenclature.name}}</td>
                        <td class="text-right">{{item.plan.toFixed(3)}}</td>
                        <td class="text-right">{{item.fact.toFixed(3)}}</td>
                        <td class="text-right">
                            <div class="input-group">
                                <button @click="enZero(item)" :disabled="item.count === 0 || disabled" class="btn btn-sm">0 <i class="fas fa-arrow-right"></i></button>
                                <input v-model="item.count" :disabled="disabled" class="form-control text-right" type="number">
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <table class="table table-sm table-bordered table-hover">
                <thead class="thead-dark">
                <tr>
                    <th colspan="5">Расход</th>
                </tr>
                <tr>
                    <th>Номенклатура</th>
                    <th class="text-right">Поступления</th>
                    <th class="text-right">План расхода</th>
                    <th class="text-right">Факт расхода</th>
                    <th class="text-right">Остаток</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="5">
                        <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрурзка...</div>
                    </td>
                </tr>
                <tr v-else-if="items.length === 0">
                    <td colspan="5">
                        <div class="alert alert-warning text-center">Нет данных</div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="item in items">
                        <td :class="{'alert-danger': hasTrouble(item)}">{{item.nomenclature.name}}</td>
                        <td class="text-right">{{item.incoming.toFixed(3)}}</td>
                        <td class="text-right">{{item.plan.toFixed(3)}}</td>
                        <td class="text-right">{{item.fact.toFixed(3)}}</td>
                        <td class="text-right">
                            <div class="input-group">
                                <button @click="enZero(item)" :disabled="item.count === 0 || disabled" class="btn btn-sm">0 <i class="fas fa-arrow-right"></i></button>
                                <input v-model="item.count" @input="$forceUpdate();" :disabled="disabled" class="form-control text-right" type="number">
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {STORAGE_MOVEMENT_ENDPOINT} from "../../../../utils/endpoints";
    import {keyBy} from 'lodash';

    export default {
        props: {
            act: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: true
            },
            showSaveButton: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        name: "Rests",
        data() {
            return {
                loading: false,
                usageReport: [],
                productionReport: [],
                items: [],
                productionItems: [],
            };
        },
        watch: {
            'act.actualShift': function (val) {
                this.load();
            },
            'act.storage': function (val) {
                this.load();
            }
        },
        methods: {
            init() {
                let actItems = {};
                if (!!this.act.items && this.act.items.length) {
                    actItems = keyBy(this.act.items, i => i.nomenclature.id);
                }

                this.items = this.usageReport.map(i => {
                    let result = i;

                    if (!!actItems[i.nomenclature.id]) {
                        result.id = actItems[i.nomenclature.id].id;
                        result.count = parseFloat(actItems[i.nomenclature.id].count);
                    } else {
                        result.id = null;
                        result.count = result.remain;
                    }

                    return result;
                });

                this.productionItems = this.productionReport.map(i => {
                    let result = i;

                    if (!!actItems[i.nomenclature.id]) {
                        result.id = actItems[i.nomenclature.id].id;
                        result.count = parseFloat(actItems[i.nomenclature.id].count);
                    } else {
                        result.id = null;
                        result.count = result.remain;
                    }

                    return result;
                })
            },
            load() {
                this.loading = true;
                this.$http.get(STORAGE_MOVEMENT_ENDPOINT + '/stuff-usage-report', {
                    params: {
                        storage_id: this.act.storage.id,
                        actual_shift_id: this.act.actualShift.id,
                        limit_by_leaving: 1,

                        without_loading: true
                    },
                    before( request ) {
                        // abort previous request, if exists
                        if ( this.previousRequest ) {
                            this.previousRequest.abort();
                        }

                        // set previous request on Vue instance
                        this.previousRequest = request;
                    }
                }).then(response => {
                    this.usageReport = response.data.usage;
                    this.productionReport = response.data.production;
                    this.init();
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            hasTrouble(item) {
                let count = parseFloat(parseFloat(item.count).toFixed(3));

                if (typeof item.incoming !== 'undefined') {
                    let incoming = parseFloat(item.incoming.toFixed(3));
                    let fact = parseFloat(item.fact.toFixed(3));


                    return Math.abs( incoming - count - fact ) > 0.005 || count < 0;
                }

                return count < 0;
            },
            enZero(item) {
                if (!!item) {
                    item.count = 0;
                } else {
                    this.items.map(i => {
                        i.count = 0;
                    });
                }
                this.$forceUpdate();
            },
            getItems() {
                let result = this.items.map(i => i);
                this.productionItems.map(i => {
                    result.push(i);
                });

                return result;
            },
            save() {

            },
        },
        mounted() {
            this.load();
            this.$emit('mounted');
        }
    }
</script>

<style scoped>
    td {
        vertical-align: middle;
    }
</style>
