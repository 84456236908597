<template>
    <div
        class="row no-gutters align-items-top transfer-row"
        :class="[transfer.modified && 'modified-row', disabled && 'disabled-row']"
    >
        <div class="col-1 checked-col">
            <b-checkbox :checked="transfer.checked" @change="onCheckedChange" :disabled="isDisabled" />
        </div>
        <div class="col-6 names-col" @click="onNamesClick">
            <span class="names">{{ names }}</span>
        </div>
        <div class="col-1 identifier-col text-center" @click="onIdentifierClick">
            <span class="identifier">{{ transfer.id }}</span>
        </div>
        <div class="col-3 direction-col" @click="onDirectionClick">
            <span class="direction">{{ direction }}</span>
        </div>
        <div class="col-1 counts-col" @click="onCountsClick">
            <span class="counts">{{ counts }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Transfer",
        props: {
            transfer: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            names() {
                return this.transfer.items.map(item => item.nomenclatureLot.nomenclature.name).join("\r\n")
            },
            direction() {
                return this.transfer.storageFrom.name + " → " + this.transfer.storageTo.name
            },
            counts() {
                return this.transfer.items.map(item => item.count + " " + item.nomenclatureLot.nomenclature.measureUnit.name).join("\r\n")
            },
            isDisabled() {
                return this.disabled || this.transfer.blocked;
            }
        },
        methods: {
            onCheckedChange() {
                if (!this.isDisabled) {
                    this.transfer.checked = !this.transfer.checked
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            onNamesClick() {
                if (!this.isDisabled) {
                    this.transfer.checked = !this.transfer.checked
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            onIdentifierClick() {
                if (!this.isDisabled) {
                    this.transfer.checked = !this.transfer.checked
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            onDirectionClick() {
                if (!this.isDisabled) {
                    this.transfer.checked = !this.transfer.checked
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            onCountsClick() {
                if (!this.isDisabled) {
                    this.transfer.checked = !this.transfer.checked
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            setChecked() {
                if (!this.isDisabled) {
                    this.transfer.checked = true
                    this.transfer.modified = this.isModified()
                    this.$emit("update:transfer", this.transfer)
                }
            },
            isModified() {
                return this.transfer.checked !== this.transfer.received
            }
        }
    }
</script>

<style scoped>
    .transfer-row {
        min-height: 28px;
        padding-top: 5px;
        padding-bottom: 4px;
    }
    .transfer-row:hover {
        cursor: pointer;
    }
    .modified-row {
        background-color: #ddfbe6;
    }
    .disabled-row {
        opacity: 0.6;
    }
    .checked-col {
        max-width: 30px;
        padding-top: 3px;
        padding-left: 6px;
    }
    .names-col {
        padding-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
    }
    .names {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .transfer-row:hover .names {
        overflow: visible;
        white-space: pre-line;
    }
    .identifier-col {
        padding-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
    }
    .identifier {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .transfer-row:hover .identifier {
        overflow: visible;
        white-space: normal;
    }
    .direction-col {
        padding-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
    }
    .direction {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .transfer-row:hover .direction {
        overflow: visible;
        white-space: pre-line;
    }
    .counts-col {
        padding-top: 3px;
    }
    .counts {
        width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
    }
    .transfer-row:hover .counts {
        overflow: visible;
        white-space: pre-line;
    }
</style>
